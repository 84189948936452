export const environment = {
  production: false,
  sandbox: false,
  appUrl: "https://dev.portal.deliverysolutions.co/",
  lynkupAppUrl: "https://dev.hub.lynkup.com/",
  serverUrl: "https://development.customer-api.deliverysolutions.co/",
  streamServiceUrl: "https://dev.streaming.deliverysolutions.co/stream/v2",
  googleMapsAPIKey: "",
  googleTagmanagerID: "GTM-TTM8QVQ",
  awsConfig: {
    fileStorage: {
      bucket: "https://development.files.deliverysolutions.co",
    },
  },
  serviceWorkerEnabled: true,
  checkUpdateInterval: 600000,
  env: "dev",
  googleMapsApiKeyDecryptionPassphrase: "sdcWq6cFjq0NLGX",
  userPilotAuthKey: "",
};
